<template>
    <div>
        <el-page-header @back="$router.go(-1)" :content="title"/>
        <el-form :model="form" :rules="rules" ref="form" label-width="150px" class="form">
            <el-form-item label="院区" prop="hospital">
                <el-radio-group v-model="form.hospital" @change="getDoctor">
                    <el-radio v-for="item in hospitalList" :key="item.id" :label="item.name">{{item.name}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="医生" prop="doctor">
                <el-radio-group v-model="form.doctor" @change="getDates">
                    <el-radio v-for="(v,k,i) in doctorList" :key="i" :label="k">{{k}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="就诊日期" prop="date">
                <el-radio-group v-model="form.date" @change="getDates">
                    <el-radio v-for="(k,i) in datesList" :key="i" :label="k">{{k}}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="就诊时间" prop="time">
                <el-radio-group v-model="form.time">
                    <el-radio v-for="(k,i) in timesList" :key="i" :label="k.Time">{{k.Time}}({{k.Used}}/{{k.Count}})</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="患者姓名" prop="patient">
                <el-input v-model="form.patient"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="idnum">
                <el-input v-model="form.idnum" @blur="handleIdnum"></el-input>
            </el-form-item>
<!--            <el-form-item label="年龄" prop="age">-->
<!--                <el-input v-model="form.age"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="性别" prop="gender">-->
<!--                <el-input v-model="form.gender"></el-input>-->
<!--            </el-form-item>-->
            <el-form-item label="联系电话" prop="phone">
                <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="既往病史简述" prop="desc">
                <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('form')">预约</el-button>
                <el-button @click="resetForm('form')">重置</el-button>
                <el-checkbox style="margin-left:20px" v-model="form.send_sms">发送短信通知</el-checkbox>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import Idcard from "@/plugins/idcard"

    export default {
        data() {
            const validateIdnum = (rule, value, callback) => {
                // 输入 8--，value 为 8
                // 估计这里内部使用了 parseInt() / parseFloat()
                let err = Idcard.hasError(value)
                if (value && err) {
                    callback(new Error(err))
                } else {
                    callback()
                }
            }
            return {
                hospitalList: [],
                doctorList: [],
                datesList: [],
                timesList: [],
                form: {
                    source: '',
                    hospital: '',
                    doctor: '',
                    date: '',
                    time: '',
                    patient: '',
                    idnum: '',
                    age: '',
                    gender: '',
                    phone: '',
                    desc: '',
                    send_sms: false,
                },
                rules: {
                    type_id: [
                        {required: true, message: '请选择类型', trigger: 'change'}
                    ],
                    hospital_id: [
                        {required: true, message: '请选择院区', trigger: 'change'}
                    ],
                    department_id: [
                        {required: true, message: '请选择科室', trigger: 'change'}
                    ],
                    doctor_id: [
                        {required: true, message: '请选择医生', trigger: 'change'}
                    ],
                    date: [
                        {required: true, message: '请选择日期', trigger: 'blur'},
                    ],
                    time: [
                        {required: true, message: '请选择时段', trigger: 'blur'},
                    ],
                    patient: [
                        {required: true, message: '请输入患者姓名', trigger: 'blur'},
                    ],
                    idnum: [
                        {required: true, validator: validateIdnum, trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '请输入联系电话', trigger: 'blur'},
                    ],
                }
            };
        },
        computed: {
            title() {
                let s = this.$route.query.s
                if (s) {
                    return s
                }
                return '预约修改'
            }
        },
        methods: {
            handleIdnum() {
                // console.log(Idcard.hasError(this.form.idnum))
                if (!Idcard.hasError(this.form.idnum)) {
                    this.form.age = Idcard.getAge(this.form.idnum).toString()
                    this.form.gender = Idcard.getGender(this.form.idnum)
                }
            },
            async submitForm() {
                const valid = await this.$refs.form.validate().then(res => res).catch(error => error);
                if (!valid) return
                if (!(this.form.idnum || (this.form.age && this.form.gender))) {
                    this.$message.error("请输入身份证或年龄性别")
                    return
                }
                let method = this.form.id ? 'put' : 'post'
                let url = this.form.id ? `/mingde/lianhua/appoint/${this.form.id}` : `/mingde/lianhua/appoint/`
                const resp = await this.$http[method](url, this.form)
                if (resp.data.code == 200) {
                    this.$message.success("保存成功！")
                    await this.$router.replace("/main/lianhua/index")
                } else {
                    this.$message.error(resp.data.msg)
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            async getHospital() {
                const hl = await this.$http.get('/mingde/hospital/')
                this.hospitalList = hl.data.data
            },
            async getDoctor() {
                const hl = await this.$http.get('/mingde/lianhua/doctors', {params: this.form})
                this.doctorList = hl.data.data
                this.getDates()
            },
            async getDates() {
                const hl = await this.$http.get('/mingde/lianhua/form', {params: this.form})
                console.log(hl)
                this.datesList = hl.data.data.dates
                this.timesList = hl.data.data.times
            },
            async getData() {
                let id = this.$route.query.id
                if (id) {
                    const resp = await this.$http.get(`/mingde/lianhua/appoint/${id}`)
                    this.form = resp.data.data
                }
                this.getDoctor()
            }
        },
        mounted() {
            this.getHospital()
            this.getData()
            let source = this.$route.query.s
            if (source) {
                this.form.source = source
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form {
        margin-top: 20px;
    }
</style>
